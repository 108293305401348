<template>
  <component
    v-if="inited"
    v-bind="props.props"
    ref="compRef"
    :is="asyncComponent"
  />
</template>

<!--
  使用此组件可以动态导入组件又可以获取组件实例Ref
  该方式会有https延时
-->

<script lang="ts" setup>
import { ref, defineAsyncComponent, defineProps } from 'vue'
import NProgress from 'nprogress'

let callback: Function
let loaded = false
const compRef = ref()
const inited = ref()
const props = defineProps<{
  module: Function,      // 动态加载的组件转入，该方法返回一个Promise；如 ()=> import('xxx.vue')
  props?: Object         // 动态加载的组件属性/参数；如 { userid: 123, name: 'AAA' }
}>()
const asyncComponent = defineAsyncComponent(() => {
  return props.module().then((res) => {
    setTimeout(() => {
      loaded = true
      callback(compRef.value)
      NProgress.done()
    })
    return res
  }).catch(() => {
    NProgress.done()
  })
})

/**
 * 外部调用即可获取动态组件的实例
 */
const getValue = (_callback: Function) => {
  inited.value = true
  callback = _callback
  if (loaded) {
    callback(compRef.value)
  } else {
    NProgress.start()
  }
}

defineExpose({
  getValue
})
</script>