import { defineStore } from 'pinia'

/**
 * 系统配置-将会缓存
 */
type State = {
  navbar: {
    breadcrumb: boolean,
    search: boolean,
    fullScreen: boolean,
    refresh: boolean,
    fold: boolean,
    menuCollapse: boolean,
    message: boolean,
    back: boolean
  },
  style: {
    element: {
      size: 'default' | 'large' | 'small' | 'mini',
      button: {
        autoInsertSpace: boolean
      },
      message: {
        max: number
      }
    },
    nProgress: boolean
  }
}

export const useConfigStore = defineStore('CONFIG', {
  state: (): State => ({
    navbar: {
      breadcrumb: true,
      search: true,
      fullScreen: true,
      refresh: true,
      fold: true,
      menuCollapse: true,
      message: true,
      back: true
    },
    style: {
      element: {
        size: 'default',
        button: {
          autoInsertSpace: false
        },
        message: {
          max: 3
        }
      },
      nProgress: true
    }
  }),
  actions: {
    setMavbar (newVal: State['navbar']) {
      this.navbar = newVal
    },
    setStyle (newVal: State['style']) {
      this.style = newVal
    },
    toggleMenuCollapse () {
      this.navbar.menuCollapse = !this.navbar.menuCollapse
    },
    clrear () {
      this.navbar = {
        breadcrumb: true,
        search: true,
        fullScreen: true,
        refresh: true,
        fold: true,
        menuCollapse: true,
        message: true,
        back: true
      }
      this.style = {
        element: {
          size: 'default'
        },
        nProgress: true
      }
    }
  }
})