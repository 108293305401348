/**
 * 判断是否为空
 * @param val
 * @returns
 */
export const isEmpty = (val: string | null | undefined): boolean => {
  return val == null || val.toString().trim() == ''
}

/**
 * 复制对象
 * @param obj
 */
export const copyObj = (obj: any): any => {
  obj = JSON.parse(JSON.stringify(obj))
  if (obj instanceof Array) {
    return obj.map(item => {
      return copyObj(item)
    })
  } else {
    if (obj == null) {
      return obj
    }
    const keys = Object.keys(obj)
    if (keys.length > 0 && obj !instanceof String) {
      const newObj: Record<string, any> = {}
      keys.forEach((key: string) => {
        newObj[key] = copyObj(obj[key])
      })
      return newObj
    } else {
      return obj
    }
  }
}

/**
 * 将options转化为键值对格式
 * @param options
 * @param keyName
 * @param valName
 * @returns
 */
export const optionsToKeyValue = (options: any[], keyName: string = 'value', valName: string = 'label'): Record<string | number, any>  => {
  const obj: Record<string | number, any> = {}
  options.forEach(item => {
    if (isEmpty(valName)) {
      obj[item[keyName]] = item
    } else {
      obj[item[keyName]] = item[valName as string]
    }
  })
  return obj
}

/**
 * 转化为对象
 * @param data
 * @returns
 */
export const toObj = (data: any) => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return data
  }
}

/**
 * 格式化数字
 * @param value 目标值
 * @param {number} min 最小值，默认0.01
 * @param {number} max 最大值，默认99999999.99
 * @returns {number} 格式化后的数字
 */
 export const formatNumber = (value: any, min: number = 0.01, max: number = 99999999.99): number => {
  let result = value
  const isPositive = parseFloat(result) > 0
  result = Math.abs(result).toString()
  result = result.match(/^\d*(\.?\d{0,2})/g)[0] || 0
  result = isPositive ? parseFloat(result) : -parseFloat(result)
  if (result < min) {
    result = min
  } else if (result > max) {
    result = max
  }
  return result
}

export const handleNumberFixed = (form, key, e, int = 8, dec = 2) => {
  if (!e || e == 0) {
    form[key] = ''
    return
  }
  const val = e.split('.')
  let integer = val[0]
  let decimals = val[1] || ''.padStart(dec, '0')
  if (integer.length > int) {
    integer = integer.slice(0, int)
  }
  if (decimals.length > dec) {
    decimals = decimals.slice(0, dec)
  }
  if (int === 0 && dec === 0) {
    form[key] = ''
  } else if (int === 0) {
    form[key] = `0.${decimals}`
  } else if (dec === 0) {
    form[key] = `${integer}`
  } else {
    form[key] = `${integer}.${decimals}`
  }
}

/**
 *
 * @param {*} arr 待去重数组
 * @param {*} key 去重字段
 * @returns 去重数组
 */
export const uniqueArrayObject = (arr = [], key = 'id') => {
  if (arr.length === 0) return []
  const result = []
  const map = new Map()
  arr.forEach(item => {
    if (!map.has(item[key])) {
      map.set(item[key], item)
      result.push(item)
    }
  })

  return result
}
