import { RouteRecordRaw } from 'vue-router'
import { routes as menuRoutes } from './menus'

const routes: RouteRecordRaw[] =  [
  {
    component: () => import('~/views/login.vue'),
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
      auth: false
    }
  },
  {
    component: () => import('~/views/mobileBindWxEnt.vue'),
    path: '/mobileBindWxEnt',
    name: 'mobileBindWxEnt',
    meta: {
      title: '绑定企业微信',
      auth: false
    }
  },
  {
    component: () => import('~/views/mobileWxEntLogin.vue'),
    path: '/mobileWxEntLogin',
    name: 'mobileWxEntLogin',
    meta: {
      title: '登录企业微信',
      auth: false
    }
  },
  {
    component: () => import('~/views/index.vue'),
    path: '/',
    name: 'Index',
    redirect: '/order/list',
    meta: {
      title: '主页'
    },
    children: [
      ...menuRoutes,
      {
        name: 'test',
        path: '/test',
        component: () => import('~/views/test.vue')
      },
      {
        name: 'reload',
        path: '/reload',
        component: () => import('~/views/reload.vue')
      },
      {
        name: '401',
        path: "/401",
        component: () => import("~/views/error-page/401.vue"),
        meta: { hidden: true },
      },
      {
        name: '404',
        path: "/404",
        component: () => import("~/views/error-page/404.vue"),
        meta: { hidden: true },
      },
      {
        name: 'loading',
        path: "/loading",
        component: () => import("~/views/error-page/Loading.vue"),
        meta: { hidden: true },
      },
    ]
  },
  {
    component: () => import('~/views/workReport/export.vue'),
    path: '/workReport/export',
    name: 'workReportExport',
    meta: {
      title: '导出作业报告'
    }
  }
]
export default routes
