import { isEmpty } from '~/utils/index'
import { App } from 'vue'
import lazyComponent from '~/components/lazyComponent.vue'
import selectOption from '~/components/selectOption.vue'

export default {
  install (app: App) {
    app.config.globalProperties.$isEmpty = isEmpty
    app.component('lazy-component', lazyComponent)
    app.component('select-option', selectOption)
  }
}