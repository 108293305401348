import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'
import { findMenu, findMenu1, addRoutes } from './menus'
import NProgress from 'nprogress'
import { useConfigStore } from '../store/config'
import { useUserStore } from '../store/user'
import { useAppStore } from '../store/app'

const router = createRouter({
  routes,
  history: createWebHashHistory()
})
const runNProgress = (start?: boolean) => {
  const configStore = useConfigStore()
  if (configStore.style.nProgress) {
    if (start) {
      NProgress.start()
    } else {
      NProgress.done()
    }
  }
}

/**
 * 生命周期项
 */
router.beforeEach((to, from, next) => {
  if (to.path != from.path) {
    runNProgress(true)
  }
  // 激活菜单
  const activeMenu = findMenu1(to, useAppStore().appConfig.menu)
  useAppStore().setMenuActive(activeMenu)
  // 权限认证
  if (Boolean(useUserStore().token) || to.meta.auth == false) {
    if (to.matched.length === 0) {
      if (!useAppStore().appConfig.menu.length) {
        getAppConfig(to.fullPath)
        return next("/loading");
      }
      if (useAppStore().appConfig.menu.length) return next("/404");
    } else {
    document.title = to.meta.title ? `飞快农服-${to.meta.title}` : '飞快农服'
    return next()
    }
  } else {
    next({
      path: '/login',
      replace: true,
      query: {
        return: encodeURIComponent(to.fullPath)
      }
    })
    return false
  }
})
function getAppConfig(path?: string) {
  import ('~/apis/config').then(({ getAppConfigApi }) => {
    getAppConfigApi({}).then((res: any) => {
      useAppStore().setAppConfig(res.obj)
      const routes = addRoutes(res.obj.menu)
      if (routes.length) {
        routes.forEach(route => {
          router.addRoute('Index', route)
        })
        // const defaultRoute = routes.find(route => route.meta.default)
        // setTimeout(() => {
        //   router.replace((defaultRoute && defaultRoute.path) || '/')
        // }, 300)
        router.replace(path || '/')
      }
    })
  })
}
router.afterEach((to, from) => {
  if (to.path != from.path) {
    runNProgress()
  }
})
router.onError((err) => {
  console.log(err)
  runNProgress()
})

export default router
