import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import element from './element/index'
import plugin from './plugin/index'
import 'nprogress/nprogress.css'
import './assets/style.scss'
import './assets/font-awesome-4.7.0/css/font-awesome.min.css'
import './element/element-ui-coustom.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 测试&&微信浏览器下的控制台
// const ua = navigator.userAgent.toLowerCase() as any
// if (import.meta.env.MODE == 'development' && ua.match(/MicroMessenger/i) == 'micromessenger') {
//   import('vconsole').then((v: any) => {
//     new v.default()
//   })
// }

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(plugin)
app.use(store)
app.use(router)
app.use(element)
app.mount('#app')
