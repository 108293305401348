import { createPinia } from 'pinia'
import { createPersistedState, Storage, Options } from 'pinia-persistedstate-plugin'
/**
 * 
 * 初始本地存储
 * 设置本地存储持久化
 */

 const store = createPinia()

 // 持久化白名单
 const persistedList: Array<string> = ['USER', 'CONFIG']
 
 // 持久化名称前缀
 const persistedKey: string = '_ARAIG_STORE'
 
 // 判断是否白名称
 const isPersistedKey = (key: string): boolean => persistedList.includes(key.replace(persistedKey + '-', ''))
 
 store.use(createPersistedState({
   key: persistedKey,
   storage: <Storage> {
     getItem (key: string): any {
       if (isPersistedKey(key)) {
         return localStorage.getItem(key)
       }
     },
     setItem (key: string, value: any): void {
       if (isPersistedKey(key)) {
        localStorage.setItem(key, value)
       }
     },
     removeItem (key: string) {
       if (isPersistedKey(key)) {
        localStorage.removeItem(key)
       }
     }
   }
 }))
 
 export default store