<template>
  <el-config-provider
    v-bind="{ ...configStore.style.element }"
    :locale="zhCn">
    <transition name="pagefade">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </transition>
  </el-config-provider>
</template>

<script setup lang="ts">
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import { ElConfigProvider } from 'element-plus'
import { useConfigStore } from './store/config'
import { useAppStore } from './store/app'
import { provide, nextTick } from 'vue'
import { useRouter } from 'vue-router'

const configStore = useConfigStore()
const appStore = useAppStore()
const router = useRouter()

/**
 * 刷新页面
 */
const reloadPage = () => {
  const route = router.currentRoute.value
  appStore.setPageReloadRouteConfig({
    query: route.query,
    params: route.params
  }, route.name as string)
  nextTick(() => {
    router.replace({
      path: '/reload'
    })
  })
}
provide('reloadPage', reloadPage)
</script>

<style lang="scss">
.pagefade-enter-active,
.pagefade-leave-active {
  transition: all .4s ease;
}

.pagefade-enter-from,
.pagefade-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}
</style>
