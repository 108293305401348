import { RouteRecordRaw, RouteLocationNormalized } from 'vue-router'
import { defineAsyncComponent } from 'vue'

// 菜单类型
export type MenuItem = {
  title: string,              // 菜单名称
  code?: string,              // 唯一标识，自动生成，一般不建议自定义
  path?: string,              // 路由路径
  handle?: Function,          // 执行事件
  component?: string,         // 组件路径,不填时将使用path生成路径 ~/views/{path},params转参数不可使用自动生成
  children?: MenuItem[],      // 子菜单
  icon?: any,                 // element-icon名称或组件
  routes?: MenuItem[],        // 子项关联路径，当前路由和子路径匹配时将定位到该菜单
  unKeepAlive?: boolean,      // 当菜单为路由菜单时，是否不缓存页面
  hide?: boolean,             // 是否隐藏
}

// 菜单配置，增删改查都改这里，最多配置两级菜单
const config: MenuItem[] = [
/*  {
    path: '/console',
    title: '控制台',
    icon: 'TrendCharts',
    component: '/console/index',
    hide: true,
    unKeepAlive: true
  },
  {
    title: '订单管理',
    code: 'order',
    icon: 'List',
    path: '/order/list',
    unKeepAlive: true
  },
  {
    title: '排程',
    code: 'schedule',
    icon: 'TrendCharts',
    path: '/schedule/index',
    unKeepAlive: true
  },
  {
    title: '订单排程',
    code: 'custom-gantt',
    icon: 'TrendCharts',
    path: '/taskGantt/index1',
    unKeepAlive: true
  },
  // {
  //   title: '客户管理',
  //   code: 'customer',
  //   icon: 'Avatar',
  //   children: [
  //     {
  //       title: '客户',
  //       path: '/customer/list'
  //     },
  //     {
  //       title: '联系人',
  //       path: '/customer/contact'
  //     }
  //   ]
  // },
  {
    title: '客户',
    code: 'customer',
    icon: 'Avatar',
    path: '/customer/list'
  },
  {
    title: '联系人',
    code: 'contact',
    icon: 'ChatDotRound',
    path: '/customer/contact'
  },
  {
    title: '数据看板',
    code: 'dataBoard',
    icon: 'ChatDotRound',
    path: '/dataBoard/index',
    unKeepAlive: true
  },
  {
    title: '作业报告',
    code: 'workReport',
    icon: 'data-line',
    children: [
      {
        title: '报告导出',
        path: '/workReport/export'
      }
    ],
    hide: true
  },
  {
    title: '资源管理',
    code: 'resource',
    icon: 'Odometer',
    children: [
      {
        title: '铁军管理',
        path: '/resourceManage/Army'
      },
      {
        title: '车辆管理',
        path: '/resourceManage/Vehicle'
      },
    ]
  },
  {
    title: '系统配置',
    code: 'config',
    icon: 'Tools',
    children: [
      {
        title: '表单配置',
        path: '/config/form/index'
      }
    ]
  },*/
]
// 生成自动code
const createCode = (item: MenuItem): string => {
  if (item.path) {
    return item.path.replace('/', '').replace(/\//g, '-')
  }
  return ''
}

/**
 * 返回菜单
 */
export const menus: MenuItem[] = ((): MenuItem[] => {
  return config.map(item => {
    if (item.code == null) {
      item.code = createCode(item)
    }
    if (item.children) {
      item.children.forEach(child => {
        child.code = createCode(child)
      })
    }
    return item
  })
})()

/**
 * 返回菜单路由
 */
const modules = import.meta.glob(['~/views/**/**.vue'])
export const routes: RouteRecordRaw[] = ((): RouteRecordRaw[] => {
  const routes: RouteRecordRaw[] = []
  const appendToRoutes = (item: MenuItem) => {
    if (item.path) {
      const name = createCode(item)
      routes.push({
        name: name,
        path: item.path,
        component: defineAsyncComponent(() => modules[(`/src/views${item.component || item.path}`) + '.vue']().then(res => {
          const newComp = res as any
          newComp.default.name = name
          return newComp
        })),
        meta: {
          title: item.title,
          keepAlive: !item.unKeepAlive
        }
      })
    }
  }
  menus.forEach(item => {
    if (item.path) {
      appendToRoutes(item)
    } else {
      if (item.children) {
        item.children.forEach(child => {
          appendToRoutes(child)
          child.routes?.forEach(route => {
            appendToRoutes(route)
          })
        })
      } else {
        item.routes?.forEach(route => {
          appendToRoutes(route)
        })
      }
    }
  })
  return routes
})()
export const addRoutes = (menus: any) => {
  const routes: any[] = []
  const appendToRoutes = (item: any) => {
    if (item.path) {
      const name = createCode(item)
      routes.push({
        name: name,
        path: item.path,
        component: defineAsyncComponent(() => modules[(`/src/views${item.component || item.path}`) + '.vue']().then(res => {
          const newComp = res as any
          newComp.default.name = name
          return newComp
        })),
        meta: {
          title: item.title,
          keepAlive: !item.unKeepAlive,
          query: item.query || {},
          default: item.default || false
        }
      })
    }
  }
  menus.forEach((item: any) => {
    if (item.path) {
      appendToRoutes(item)
    } else {
      if (item.children) {
        item.children.forEach(child => {
          appendToRoutes(child)
          child.routes?.forEach(route => {
            appendToRoutes(route)
          })
        })
      } else {
        item.routes?.forEach(route => {
          appendToRoutes(route)
        })
      }
    }
  })
  return routes
}
export const findMenu1 = (to: RouteLocationNormalized, menus: object[]): FindMenuPos => {
  function sortObjectKeys(obj) {
    obj = obj || {};
    return Object.keys(obj).sort().reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
  }
  const path = to.path
  for (let i = 0; i < menus.length; i ++) {
    const menu = menus[i]
    if (menu.path === path && path == '/order/list') {
      return {
        index: i,
        menu
      }
    } else if (menu.path == path && JSON.stringify(sortObjectKeys(menu.query)) === JSON.stringify(sortObjectKeys(to.query))) {
      return {
        index: i,
        menu
      }
    } else if (menu.children) {
      for (let j = 0; j < menu.children.length; j ++) {
        const child = menu.children[j]
        if (child.path == path) {
          return {
            index: i,
            childIndex: j,
            menu: child
          }
        }
        if (child.routes) {
          for (let route of child.routes) {
            if (route.path == path) {
              return {
                index: i,
                childIndex: j,
                menu: child
              }
            }
          }
        }
      }
    } else if (menu.routes) {
      const route = menu.routes.find(item => {
        return item.path == path
      })
      if (route) {
        return {
          index: i,
          menu: menu
        }
      }
    }
  }
  return {
    index: 0,
    menu: menus[0]
  }
}

/**
 * 寻找匹配的菜单
 * @param path
 */
 export type FindMenuPos = {
  index: number,
  menu: MenuItem,
  childIndex?: number | null
}
export const findMenu = (path: string): FindMenuPos => {
  for (let i = 0; i < menus.length; i ++) {
    const menu = menus[i]
    if (menu.path == path) {
      return {
        index: i,
        menu
      }
    } else if (menu.children) {
      for (let j = 0; j < menu.children.length; j ++) {
        const child = menu.children[j]
        if (child.path == path) {
          return {
            index: i,
            childIndex: j,
            menu: child
          }
        }
        if (child.routes) {
          for (let route of child.routes) {
            if (route.path == path) {
              return {
                index: i,
                childIndex: j,
                menu: child
              }
            }
          }
        }
      }
    } else if (menu.routes) {
      const route = menu.routes.find(item => {
        return item.path == path
      })
      if (route) {
        return {
          index: i,
          menu: menu
        }
      }
    }
  }
  return {
    index: 0,
    menu: menus[0]
  }
}
