import { defineStore } from 'pinia'
import { nextTick } from 'vue'
import { copyObj } from '../utils/index'
import { FindMenuPos, menus } from '../router/menus'

/**
 * 运行变量
 */
export const useAppStore = defineStore('APP', {
  state: () => ({
    appConfig: {
      menu: [],
      features: {}
    },
    menuActive: {
      index: 0,
      menu: menus[0]
    } as FindMenuPos,
    pageReloadConfig: {
      routeExclude: null as string | null,
      routeParams: {} as any
    }
  }),
  actions: {
    setAppConfig(payload: any) {
      this.appConfig = payload
    },
    setMenuActive (menuItem: FindMenuPos) {
      this.menuActive = menuItem
    },
    setPageReloadRouteConfig (params: any, name: string) {
      this.pageReloadConfig.routeExclude = name
      this.pageReloadConfig.routeParams = copyObj(params)
    },
    runPageReload (): Promise<any> {
      return new Promise((resolve: Function) => {
        nextTick(() => {
          const name = this.pageReloadConfig.routeExclude
          this.pageReloadConfig.routeExclude = null
          nextTick(() => {
            resolve({
              name,
              params: this.pageReloadConfig.routeParams,
              done: () => {
                this.pageReloadConfig.routeParams = {}
              }
            })
          })
        })
      })
    }
  },
  getters: {
    routeExcludes (): string[] {
      const names = []
      if (this.pageReloadConfig.routeExclude) {
        names.push(this.pageReloadConfig.routeExclude)
      }
      return names
    },
    menuActiveCode (): string | undefined {
      return this.menuActive?.menu?.code
    }
  }
})
