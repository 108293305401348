import { Base64 } from 'js-base64'

// 加
export const encode = (str: string) => {
  return Base64.encode(str)
}

//
export const decode = (str: string) => {
  return Base64.decode(str)
}