<template>
  <el-select
    v-bind="$attrs"
    :modelValue="modelValue"
    @change="valueChangeHandle">
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :value="item[valueKey]"
      :label="item[labelKey]">
      <template #default>
        <slot>{{ item[labelKey] }}</slot>
      </template>
    </el-option>
  </el-select>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'

const props = withDefaults(defineProps<{
  options: Array<any>,
  labelKey: string,
  valueKey: string,
  modelValue: string
}>(), {
  options: () => [],
  labelKey: 'label',
  valueKey: 'value',
})
const emit = defineEmits(['update:modelValue', 'update:label', 'change'])

const valueChangeHandle = (val: any) => {
  const item = props.options.find(i => {
    return i[props.valueKey] == val
  })
  emit('update:modelValue', val)
  emit('update:label', item?.label)
  emit('change', { ...item })
}
</script>