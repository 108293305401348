import { defineStore } from 'pinia'
import { encode, decode } from '../utils/encrypt'

type NullOrString = null | string

type AutoFill = {
  account: NullOrString,
  password: NullOrString
}

export const useUserStore = defineStore('USER', {
  state: () => ({
    token: null as NullOrString,
    autoFillEncrypt: null as NullOrString,
    data: {
      name: null as NullOrString,
      avatar: null as NullOrString
    }
  }),
  actions: {
    setToken (token: string) {
      this.token = token
    },
    setData (data: any) {
      this.data = data
    },
    setAutoFillToEncrypt (val: AutoFill) {
      this.autoFillEncrypt = encode(JSON.stringify(val))
      console.log(encode(JSON.stringify(val)))
    },
    getAutoFill (): AutoFill {
      if (this.autoFillEncrypt) {
        return JSON.parse(decode(this.autoFillEncrypt)) as AutoFill
      }
      return { account: null, password: null }
    },
    clrearAutoFill () {
      this.autoFillEncrypt = null
    },
    clrear () {
      this.autoFillEncrypt = null
      this.token = null
      this.data = {
        name: null,
        avatar: null
      }
    }
  }
})